import axios from 'axios'
import qs from 'qs'

const instance = axios.create({
  baseURL: '',
  timeout: 50000
})

instance.defaults.transformRequest = (data) => qs.stringify(data)

instance.interceptors.request.use(
  (config) => {
    setHeader(config)
    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    let { response } = error

    if (response) {
      switch (response.status) {
        case 401:
          break
        case 403:
          break
        case 404:
          break
      }
    } else {
      if (!window.navigator.onLine) {
        return
      }

      return Promise.reject(error)
    }
  }
)

function setHeader(config) {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  // config.headers["xx-user-token"] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJ4eC11c2VyLWlkIjoiNjQ1NTYiLCJpc3MiOiJzaG91Z3VhIiwiZXhwIjoxNzI5Njk2MDg0LCJpYXQiOjE3MjcxMDQwODR9.YKvGNr4gYccqLZV-mVOArANXO0LmxSqf8Cug6ssUvHTSl7MNWs57Y6Kw-DCUWzoAZNRQwQRnuO2N0kzc7QYJ7g.467CQhK';
  return
}

export default instance
